var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Scelta data")]),_c('v-card-text',[_c('v-date-picker',{attrs:{"locale":"it-it","full-width":""},on:{"change":function($event){_vm.dateDialog=false}},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dateDialog=false}}},[_vm._v("Annulla")])],1)],1)],1),_c('v-card',{attrs:{"elevation":"10"}},[_c('v-card-title',[_vm._v("Nuovo criterio")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.searchTypes,"item-text":"fieldName","item-value":"field","label":"Entità"},on:{"change":_vm.resetFields},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.selectedType)?_c('v-select',{attrs:{"items":_vm.searchOperators,"item-text":"operatorName","item-value":"operator","label":"Operatore"},model:{value:(_vm.selectedOperator),callback:function ($$v) {_vm.selectedOperator=$$v},expression:"selectedOperator"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[(
              _vm.selectedType &&
              _vm.selectedOperator &&
              _vm.selectedInputType &&
              (_vm.selectedInputType === 'text' ||
                _vm.selectedInputType === 'number' ||
                _vm.selectedInputType === 'float')
            )?_c('v-text-field',{attrs:{"type":_vm.selectedInputType === 'float' || _vm.selectedInputType === 'number'
                ? 'number'
                : 'text',"step":_vm.selectedInputType === 'float'
                ? '0.01'
                : _vm.selectedInputType === 'number'
                ? '1'
                : '',"label":_vm.selectedLabel},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}):_vm._e(),(
              _vm.selectedType &&
              _vm.selectedOperator &&
              _vm.selectedInputType &&
              _vm.selectedInputType === 'date'
            )?_c('v-text-field',{attrs:{"readonly":true,"label":_vm.selectedLabel},on:{"click":function($event){_vm.dateDialog=true}},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dateDialog=true}}},[_c('v-icon',[_vm._v("mdi-calendar-month-outline")])],1)],1)],2):_vm._e(),(
              _vm.selectedType &&
              _vm.selectedOperator &&
              _vm.selectedInputType &&
              _vm.selectedInputType === 'removed'
            )?_c('div',[_c('br'),_vm._v("scaricato ")]):_vm._e(),(
              _vm.selectedType &&
              _vm.selectedOperator &&
              _vm.selectedInputType &&
              _vm.selectedInputType === 'f_dotaz'
            )?_c('div',[_c('br'),_vm._v("Fondo dotazione dipartimento ")]):_vm._e(),(
              _vm.selectedType &&
              _vm.selectedOperator &&
              _vm.selectedInputType &&
              _vm.selectedInputType === 'list'
            )?_c('v-select',{attrs:{"items":_vm.itemlist},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}):_vm._e(),(
              _vm.selectedType &&
              _vm.selectedOperator &&
              _vm.selectedInputType &&
              _vm.selectedInputType === 'user'
            )?_c('v-autocomplete',{staticClass:"mx-4",attrs:{"loading":_vm.usersLoading,"items":_vm.users,"search-input":_vm.searchuser,"item-text":"text","item-value":"MATRICOLA","cache-items":"","flat":"","hide-no-data":"","hide-details":"","return-object":"","label":"Ricerca persona"},on:{"update:searchInput":function($event){_vm.searchuser=$event},"update:search-input":function($event){_vm.searchuser=$event}},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}):_vm._e(),(
              _vm.selectedType &&
              _vm.selectedOperator &&
              _vm.selectedInputType &&
              _vm.selectedInputType === 'room'
            )?_c('v-autocomplete',{staticClass:"mx-4",attrs:{"loading":_vm.roomsLoading,"items":_vm.rooms,"search-input":_vm.searchroom,"item-text":"text","item-value":"ID","cache-items":"","flat":"","hide-no-data":"","hide-details":"","return-object":"","label":"Ricerca stanza"},on:{"update:searchInput":function($event){_vm.searchroom=$event},"update:search-input":function($event){_vm.searchroom=$event}},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}):_vm._e()],1)],1),_c('br'),_c('v-row',[_c('v-spacer'),(_vm.readyToAdd)?_c('v-btn',{attrs:{"disabled":!_vm.readyToAdd},on:{"click":_vm.addRule}},[_vm._v("Aggiungi regola")]):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v("Annulla")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }